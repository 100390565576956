  import React from 'react'
  import Layout from '../../components/App/Layout'
  import Navbar from '../../components/App/NavbarRV'
  import Footer from '../../components/App/FooterRV'
  import KDPImagePage from '../../assets/images/pages/1-0-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li>Expertise construction</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Quand demander une expertise construction ?</h1>

<p>L’expertise construction a pour principal objectif d’examiner les désordres qui affectent une construction afin d’en rechercher les causes, de définir des remèdes et leurs coûts et de dégager des responsabilités.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise construction" />
</div>


<p>Les pathologies affectant une construction peuvent être de différentes natures :</p>
<ul><li>malfaçons,</li>
<li>fissures, lézardes,</li>
<li>humidité,</li>
<li>conception d’ouvrage et réalisation.</li></ul>
<h1>Une assistance technique pour différentes procédures</h1>

<p>Face à la récurrence de problématiques de fissurations d’ouvrages, les propriétaires Maître d’Ouvrage et assurés se retrouvent parfois confrontés à des refus de garanties par leur assurance, pour des motifs qu’ils ne comprennent pas.</p>

<p>L’expert construction intervient :</p>
<ul><li>dans le cadre d’une demande d’expertise unilatérale : pour un avis technique ponctuel (avis avant acquisition, avis suite à désordres,)</li>
<li>pour accompagner un assuré afin de régler un litige : l’expert construction défend alors, sur le plan technique, les intérêts de l’assuré sinistré,</li>
<li>dans le cadre d’une expertise judiciaire : l’expert construction intervient également sur le plan technique, et permet au conseil juridique d’appuyer son argumentaire. </li></ul>

<h1>L’expertise construction - prestation du cabinet RV Expertises</h1>

<p>Les Experts construction du cabinet RV Expertises sont à votre disposition pour différents cadres d’expertise construction :</p>

<ul><li>Expertise d’assuré, assistance technique dans le cadre d’un sinistre construction non garanti,</li>
<li>Avis technique sur construction et préconisations,</li>
<li>Recours amiable : Expertise amiable et contre expertise,</li>
<li>Assistance Catastrophes Naturelles,</li>
<li>Assistance Expertise judiciaire,</li></ul>

<p>Pour plus d’informations ou une demande de devis, vous pouvez contacter le cabinet RV Expertises, nous vous répondrons dans les 48 heures.  </p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert Construction</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/' className='active'>Expertise construction</a></li><li><a href='/expertise-construction/expert-construction/' >L'expert construction</a></li><li><a href='/expertise-construction/expertise-fissures/' >Expertise fissures</a></li><li><a href='/expertise-construction/expertise-humidite/' >Expertise Humidité</a></li><li><a href='/expertise-construction/conseil-achat-immobilier/' >Avis avant acquisition immobilière</a></li><li><a href='/expertise-construction/expertise-batiment/' >Expertise technique batiment</a></li><li><a href='/expertise-construction/assistance-reception-immobilier/' >Assistance réception bien immobilier ou travaux</a></li><li><a href='/expertise-construction/mur-soutenement/' >Mur de soutènement</a></li><li><a href='/expertise-construction/toiture/' >Toiture</a></li><li><a href='/expertise-construction/balcon/' >Balcon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details